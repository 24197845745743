import Swiper from "swiper";
import { FreeMode, Mousewheel, Navigation, } from "swiper/modules";
import GenericSwiper from "./GenericSwiper";
class ScrollSlider extends GenericSwiper {
    constructor() {
        super(...arguments);
        this.swiperScrollComplete = false;
        this.containerWidth = null; // width of the container-full class
        this.titleSize = null; // width of the title
        this.swiperCardWidth = null; // width of the title
        this.spaceBetween = 20;
        this.viewportOffset = 400;
    }
    static create(element) {
        return new this(element);
    }
    getOffset() {
        // screen viewport size
        const viewportWidth = window.innerWidth;
        // console.log("[ScrollSlider.ts] viewportWidth: ", viewportWidth, " containerWidth: ", this.containerWidth);
        // if the viewport is larger than the container
        if (this.containerWidth && viewportWidth > this.containerWidth) {
            // return the difference between the viewport and container
            return ((viewportWidth - this.containerWidth) / 2);
        }
        return 20; // if here must be mobile, so just get gutter
    }
    static viewportWithinElement(viewportBottom, elementTopPosition, elementButtomPosition) {
        return (viewportBottom > elementTopPosition && viewportBottom < elementButtomPosition);
    }
    static isViewportBeforeElement(viewportBottom, elementTopPosition) {
        return viewportBottom < elementTopPosition;
    }
    static isViewportAfterElement(viewportBottom, elementBottomPosition) {
        return viewportBottom > elementBottomPosition;
    }
    applyActiveClass() {
        this.element.classList.add("active");
    }
    removeActiveClass() {
        this.element.classList.remove("active");
    }
    percentageScrolled() {
        var _a, _b;
        // bottom of the screen
        // const viewportCentre: number = ((window.innerHeight / 2) + window.scrollY) - this.viewportOffset;
        // const viewportBottom = window.innerHeight + window.scrollY;
        // const viewportTop = window.scrollY + this.viewportOffset;
        const viewportTop = window.scrollY;
        // top of the element
        const top = this.element.offsetTop;
        // height of the swiper
        const swiperHeight = (_b = (_a = this.swiperElement) === null || _a === void 0 ? void 0 : _a.offsetHeight) !== null && _b !== void 0 ? _b : 0;
        // bottom of the element
        const elementBottom = (top + this.element.offsetHeight) - (swiperHeight + 375);
        // console.log("[ScrollSlider.ts] viewportTop: ", viewportTop, " top: ", top, " elementBottom: ", elementBottom);
        if (ScrollSlider.viewportWithinElement(viewportTop, top, elementBottom)) {
            const percentage = ((viewportTop - top) / (this.element.offsetHeight - (swiperHeight + 375))) * 100;
            this.applyActiveClass();
            return percentage;
        }
        this.removeActiveClass();
        if (ScrollSlider.isViewportBeforeElement(viewportTop, top)) {
            return 0;
        }
        return 100;
    }
    calculateSwiperWidth() {
        var _a;
        if (this.swiper) {
            // get the width of the swiper
            const offset = this.getOffset();
            const swiperWidth = this.swiper.width;
            const swiperSlides = this.swiper.slides.length;
            const swiperSlideSpace = this.spaceBetween;
            const titleSize = (_a = this.titleSize) !== null && _a !== void 0 ? _a : 0;
            return (((swiperWidth * swiperSlides)
                + (swiperSlideSpace * (swiperSlides - 1))) + offset + titleSize) - (375 * 2);
        }
        return 0;
    }
    calculateSwiperHeightOffset() {
        const viewportHeight = window.innerHeight;
        if (this.swiper) {
            const swiperHeight = this.swiper.height;
            return (viewportHeight - swiperHeight) / 2;
        }
        return 0;
    }
    setSwiperAttributes() {
        const swiperWidth = this.calculateSwiperWidth();
        const swiperWidthWithViewportOffset = swiperWidth + (this.viewportOffset * 2); // increasing top and bottom padding
        // console.log("[ScrollSlider.ts] swiperWidth: ", swiperWidth, " swiperWidthWithViewportOffset: ", swiperWidthWithViewportOffset);
        const swiperHeightOffset = this.calculateSwiperHeightOffset();
        this.element.setAttribute("style", `height: ${swiperWidthWithViewportOffset - (swiperHeightOffset * 2)}px;`);
    }
    scrollEvent(totalSwiperWidth) {
        var _a;
        const percentageScroll = this.percentageScrolled();
        const percentageOfWidth = (percentageScroll / 100) * (totalSwiperWidth + this.viewportOffset);
        (_a = this.swiper) === null || _a === void 0 ? void 0 : _a.translateTo(-percentageOfWidth, 0, false, false);
    }
    calculateViewportOffset() {
        var _a, _b;
        if (!this.swiper) {
            return; // return previous value (likely 400)
        }
        // screen viewport size
        const viewportHeight = window.innerHeight;
        // getting the height of the swiper
        const swiperHeight = (_b = (_a = this.swiper) === null || _a === void 0 ? void 0 : _a.height) !== null && _b !== void 0 ? _b : 0;
        // getting the position of the swiper relative to the top of the screen
        this.viewportOffset = (viewportHeight - swiperHeight) / 2;
    }
    init() {
        var _a;
        if (this.swiperElement) {
            if (window.innerWidth >= 991) {
                this.containerWidth = 1380;
                this.titleSize = 480;
                this.swiperCardWidth = 555;
                this.spaceBetween = 20;
                this.viewportOffset = 400;
                // scroll params
                this.args.width = this.swiperCardWidth;
                this.args.spaceBetween = 20;
                this.args.modules = [...(_a = this.args.modules) !== null && _a !== void 0 ? _a : [], Mousewheel, FreeMode];
                this.args.freeMode = true;
                this.swiper = new Swiper(this.swiperElement, this.args);
                this.calculateViewportOffset();
                const swiperWidth = this.calculateSwiperWidth();
                this.setSwiperAttributes();
                window.addEventListener("resize", () => {
                    this.setSwiperAttributes();
                    this.calculateViewportOffset();
                });
                window.addEventListener("scroll", () => {
                    this.scrollEvent(swiperWidth);
                });
            }
            else {
                this.swiper = new Swiper(this.swiperElement, {
                    modules: [Navigation],
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                        enabled: true,
                    },
                    spaceBetween: 10,
                    slidesPerView: "auto",
                    breakpoints: {
                        767: {
                            slidesPerView: 2,
                        },
                    },
                });
            }
        }
    }
}
export default ScrollSlider;
